import Axios from '@/axios'

/**

 * 岗位管理
 * -  岗位管理列表 get.position.list
 * -  岗位管理删除 del.position.list
 * -  岗位管理修改 set.position.item
 * -  岗位管理新增 add.position.item
 * -  设置热门岗位 set.position.hot
 * -  查询岗位不分页 get.position.select
 */
export const position = function (data={}) {
  return Axios({
    url: '/adminapi/v1/position',
    method: 'post',
    data,
  })
}
