const project = {
  namespaced: true,
  state: {
    plan:{},
  },
  mutations: {
    SET_PLAN: (state, payload) => {
        var data = state.plan.deepClone();
        data[payload.projectId] = payload.plan;
        state.plan = data;
    },
    DEL_PLAN: (state, payload) => {
      var data = state.plan.deepClone();
      var r = data[payload.projectId];
      for(let i=0;i<r.length;i++){
        if(r[i].id==payload.id){
          r.splice(i,1);
        }
      };
      state.plan = data;

    },
  },
  actions: {
    setPlan({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_PLAN',{data});
        resolve();
      });
    },
    delPlan({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('DEL_PLAN',{data});
        resolve();
      });
    },
  }
}

export default project
