import Axios from '@/axios'

/**

 * 优惠券管理
 * -  优惠券列表 get.coupon.list
 * -  优惠券删除 del.coupon.list
 * -  优惠券修改 set.coupon.item
 * -  优惠券新增 add.coupon.item
 */
export const coupon = function (data={}) {
  return Axios({
    url: '/adminapi/v1/report',
    method: 'post',
    data,
  })
}
