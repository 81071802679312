import Axios from '@/axios'

/**

 * 商品类型管理
 * -  商品类型列表 get.goods_category.list
 * -  商品类型删除 del.goods_category.list
 * -  商品类型修改 set.goods_category.item
 * -  商品类型新增 add.goods_category.item
 * -  商品类型查询 get.goods_category.select
 */
export const goodsCategory = function (data={}) {
  return Axios({
    url: '/adminapi/v1/goods_category',
    method: 'post',
    data,
  })
}
