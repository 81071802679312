import Axios from '@/axios'

/**

 * 招聘标签管理
 * -  招聘标签管理列表 get.jobs_tag.list
 * -  招聘标签管理删除 del.jobs_tag.list
 * -  招聘标签管理修改 set.jobs_tag.item
 * -  招聘标签管理新增 add.jobs_tag.item
 */
export const jobsTag = function (data={}) {
  return Axios({
    url: '/adminapi/v1/jobs_tag',
    method: 'post',
    data,
  })
}
