import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dayjs from 'dayjs'

import './prototype.js'
import './filter.js'

//自定义控件挂载
Vue.config.productionTip = false

import ElementUI from 'element-ui'
import './styles/icon/iconfont.css'
import './styles/element-variables.scss'
import 'element-ui/lib/theme-chalk/index.css'
import './elementui.js'
Vue.use(ElementUI)

import './styles/common.scss'

import api from './api/index'
Vue.prototype.$api = api
Vue.prototype.$dayjs = dayjs

// 引入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
