import Axios from '@/axios'


export const auth_group = function (data={}) {
  return Axios({
    url: '/adminapi/v1/auth_group',
    method: 'post',
    data,
  })
}

export const auth_rule = function (data={}) {
  return Axios({
    url: '/adminapi/v1/auth_rule',
    method: 'post',
    data,
  })
}
