import Axios from '@/axios'


export const addressTag = function (data={}) {
  return Axios({
    url: '/adminapi/v1/address_tag',
    method: 'post',
    data,
  })
}
