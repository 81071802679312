import Axios from '@/axios'

export const ask = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/ask',
    method: 'post',
    data,
  })
}

export const exchange = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/exchange',
    method: 'post',
    data,
  })
}

export const exchangeLog = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/exchange_log',
    method: 'post',
    data,
  })
}
