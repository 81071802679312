import Vue from 'vue'
import VueRouter from 'vue-router'
import Axios from '@/axios'
import store from '@/store'
import { initTreeData } from '@/util/common'
import { Message } from 'element-ui'
import api from '@/api/index'
import { menu } from '@/api/menu'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  // mode: 'history',
  //base: process.env.BASE_URL,
  routes: [
    {
      path: '/login', // 登录页面
      name: 'Login',
      meta: { title: '登录', verificationLogin: true },
      component: () => import('@/views/Login.vue'),
    },
  ],
})

router.beforeEach((to, from, next) => {
  // 跳转的页面是登录页
  if (to.meta.verificationLogin) {
    next()
  } else {
    if (store.state.user.userInfo.token.token) {
      if (store.state.user.loadMenus) {
        // 判断是否已拉取过菜单防止死循环
        next()
      } else {
        loadMenus(next, to)
      }
    } else {
      next(`/login?redirect=${to.path}`)
    }
  }
})

const loadMenus = (next, to) => {
  Promise.all([
    api.menu.menu({
      method: 'get.menu.list',
      module: 'admin',
    }),
    api.role.auth_rule({
      method: 'get.auth.rule.list',
      module: 'admin',
      group_id: store.state.user.userInfo.admin.group_id,
    }),
  ])
    .then((r) => {
      var menuData = []
      var authorization = []
      var authRule = []

      //下面数据没有到 防止后台菜单数据丢失 所以这里备份下
      var res = {
        status: 200,
        message: 'success',
        data: [
          {
            menu_id: 587,
            parent_id: 0,
            name: '系统管理',
            alias: '',
            icon: 'iconassessed-badge',
            remark: '',
            module: 'admin',
            type: 0,
            url: '',
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'SYSTEM',
            component_url: '',
            children_total: 4,
            level: 0,
          },
          {
            menu_id: 631,
            parent_id: 587,
            name: '管理员管理',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'SYSTEM_USER',
            component_url: 'views/system/user/Index',
            children_total: 0,
            level: 1,
          },
          {
            menu_id: 632,
            parent_id: 587,
            name: '菜单管理',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'MENU',
            component_url: 'views/system/menu/Index',
            children_total: 0,
            level: 1,
          },
          {
            menu_id: 633,
            parent_id: 587,
            name: '角色管理',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: '',
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'role',
            component_url: 'views/system/role/Index',
            children_total: 0,
            level: 1,
          },
          {
            menu_id: 634,
            parent_id: 587,
            name: '操作日志管理',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'SYSTEM_LOG',
            component_url: 'views/system/log/Index',
            children_total: 0,
            level: 1,
          },
          {
            menu_id: 590,
            parent_id: 0,
            name: '会员管理',
            alias: '',
            icon: 'icon-7',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'MEMBERMGR',
            component_url: '',
            children_total: 6,
            level: 0,
          },
          {
            menu_id: 610,
            parent_id: 590,
            name: '会员等级管理',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'USERLEVEL',
            component_url: 'views/user/level/Index',
            children_total: 0,
            level: 1,
          },
          {
            menu_id: 611,
            parent_id: 590,
            name: '用户反馈管理',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'IDEA',
            component_url: 'views/user/idea/Index',
            children_total: 0,
            level: 1,
          },
          {
            menu_id: 612,
            parent_id: 590,
            name: '用户收藏记录管理',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'COLLECTION',
            component_url: 'views/user/collection/Index',
            children_total: 0,
            level: 1,
          },
          {
            menu_id: 613,
            parent_id: 590,
            name: '用户认证管理',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: '',
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'audit',
            component_url: 'views/user/audit/Index',
            children_total: 0,
            level: 1,
          },
          {
            menu_id: 614,
            parent_id: 590,
            name: '用户评论管理',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'MOMENT',
            component_url: 'views/user/moment/Index',
            children_total: 0,
            level: 1,
          },
          {
            menu_id: 615,
            parent_id: 590,
            name: '用户列表',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'USERLIST',
            component_url: 'views/user/user/Index',
            children_total: 0,
            level: 1,
          },
          {
            menu_id: 591,
            parent_id: 0,
            name: '课程管理',
            alias: '',
            icon: 'icon-_kecheng',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'COURSEMGR',
            component_url: '',
            children_total: 7,
            level: 0,
          },
          {
            menu_id: 616,
            parent_id: 591,
            name: '课程列表',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'COURSELIST',
            component_url: 'views/course/course/Index',
            children_total: 0,
            level: 1,
          },
          {
            menu_id: 617,
            parent_id: 591,
            name: '机构/讲师管理',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'ORGAN',
            component_url: 'views/course/organ/Index',
            children_total: 0,
            level: 1,
          },
          {
            menu_id: 618,
            parent_id: 591,
            name: '课程分类',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'COURSECAT',
            component_url: 'views/course/cat/Index',
            children_total: 0,
            level: 1,
          },
          {
            menu_id: 619,
            parent_id: 591,
            name: '课程标签',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'COURSETAG',
            component_url: 'views/course/tag/Index',
            children_total: 0,
            level: 1,
          },
          {
            menu_id: 620,
            parent_id: 591,
            name: '笔记管理',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'NOTE',
            component_url: 'views/course/note/Index',
            children_total: 2,
            level: 1,
          },
          {
            menu_id: 635,
            parent_id: 620,
            name: '通过',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: '',
            params: '',
            target: '_self',
            is_navi: 0,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'NOTE_ADOPT',
            component_url: '',
            children_total: 0,
            level: 2,
          },
          {
            menu_id: 637,
            parent_id: 620,
            name: '拒绝',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: '',
            params: '',
            target: '_self',
            is_navi: 0,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'NOTE_REFUSE',
            component_url: '',
            children_total: 0,
            level: 2,
          },
          {
            menu_id: 621,
            parent_id: 591,
            name: '课程章节',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'COURSEVIDEO',
            component_url: 'views/course/video/Index',
            children_total: 0,
            level: 1,
          },
          {
            menu_id: 638,
            parent_id: 591,
            name: '笔记评论管理',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: '',
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'NOTEREPLY',
            component_url: 'views/course/noteReply/Index',
            children_total: 2,
            level: 1,
          },
          {
            menu_id: 639,
            parent_id: 638,
            name: '通过',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: '',
            params: '',
            target: '_self',
            is_navi: 0,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'NOTEREPLY_REFUSE',
            component_url: '',
            children_total: 0,
            level: 2,
          },
          {
            menu_id: 640,
            parent_id: 638,
            name: '通过',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: '',
            params: '',
            target: '_self',
            is_navi: 0,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'NOTEREPLY_ADOPT',
            component_url: '',
            children_total: 0,
            level: 2,
          },
          {
            menu_id: 592,
            parent_id: 0,
            name: '资讯管理',
            alias: '',
            icon: 'icon-zixun',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'ARTICLE',
            component_url: '',
            children_total: 2,
            level: 0,
          },
          {
            menu_id: 622,
            parent_id: 592,
            name: '资讯分类管理',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'ARTICLECAT',
            component_url: 'views/article/articleCat/Index',
            children_total: 0,
            level: 1,
          },
          {
            menu_id: 624,
            parent_id: 592,
            name: '资讯内容管理',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'ARTICLECONTENT',
            component_url: 'views/article/article/Index',
            children_total: 0,
            level: 1,
          },
          {
            menu_id: 594,
            parent_id: 0,
            name: '商品管理',
            alias: '',
            icon: 'icon-shangpin',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'GOODMANAGE',
            component_url: '',
            children_total: 3,
            level: 0,
          },
          {
            menu_id: 628,
            parent_id: 594,
            name: '优惠券管理',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'COUPON',
            component_url: 'views/coupon/Index',
            children_total: 0,
            level: 1,
          },
          {
            menu_id: 629,
            parent_id: 594,
            name: '商品分类管理',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'GOODTYPE',
            component_url: 'views/goodManage/goodsCategory/Index',
            children_total: 0,
            level: 1,
          },
          {
            menu_id: 630,
            parent_id: 594,
            name: '商品管理',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'GOOD',
            component_url: 'views/goodManage/good/Index',
            children_total: 0,
            level: 1,
          },
          {
            menu_id: 631,
            parent_id: 594,
            name: '订单管理',
            alias: '',
            icon: '',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'ORDERLIST',
            component_url: 'views/goodManage/orderList/Index',
            children_total: 0,
            level: 1,
          },
          {
            menu_id: 595,
            parent_id: 0,
            name: '举报管理',
            alias: '',
            icon: 'icon-jubao',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'report',
            component_url: 'views/report/Index',
            children_total: 0,
            level: 0,
          },
          {
            menu_id: 596,
            parent_id: 0,
            name: '积分任务管理',
            alias: '',
            icon: 'icon-_jifen',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'TASK',
            component_url: 'views/task/Index',
            children_total: 0,
            level: 0,
          },
          {
            menu_id: 597,
            parent_id: 0,
            name: '成就任务详情',
            alias: '',
            icon: 'icon-jiashichengjiu',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'TASKATTR',
            component_url: 'views/taskAttr/Index',
            children_total: 0,
            level: 0,
          },
          {
            menu_id: 598,
            parent_id: 0,
            name: '招聘标签',
            alias: '',
            icon: 'icon-biaoqian',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'JOBSTAG',
            component_url: 'views/jobsTag/Index',
            children_total: 0,
            level: 0,
          },
          {
            menu_id: 599,
            parent_id: 0,
            name: '招聘管理',
            alias: '',
            icon: 'icon-zhaopingangwei',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'JOBS',
            component_url: 'views/jobs/Index',
            children_total: 0,
            level: 0,
          },
          {
            menu_id: 600,
            parent_id: 0,
            name: '招聘岗位',
            alias: '',
            icon: 'icon-gangweiguanli',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'POSITION',
            component_url: 'views/position/Index',
            children_total: 0,
            level: 0,
          },
          {
            menu_id: 601,
            parent_id: 0,
            name: '求职管理',
            alias: '',
            icon: 'icon-_qiuzhijiqiao',
            remark: '',
            module: 'admin',
            type: 0,
            url: '',
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'resume',
            component_url: 'views/resume/Index',
            children_total: 0,
            level: 0,
          },
          {
            menu_id: 602,
            parent_id: 0,
            name: '会员卡管理',
            alias: '',
            icon: 'icon-wodehuiyuanqia',
            remark: '',
            module: 'admin',
            type: 0,
            url: '',
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'userLevel',
            component_url: 'views/userLevel/Index',
            children_total: 0,
            level: 0,
          },
          {
            menu_id: 603,
            parent_id: 0,
            name: '地址标签管理',
            alias: '',
            icon: 'icon-dizhi',
            remark: '',
            module: 'admin',
            type: 0,
            url: null,
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'addressTag',
            component_url: 'views/addressTag/Index',
            children_total: 0,
            level: 0,
          },
          {
            menu_id: 604,
            parent_id: 0,
            name: '快递公司',
            alias: '',
            icon: 'icon-kuaidihuoyun',
            remark: '',
            module: 'admin',
            type: 0,
            url: '',
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'delivery',
            component_url: 'views/delivery/Index',
            children_total: 0,
            level: 0,
          },
          {
            menu_id: 605,
            parent_id: 0,
            name: '广告管理',
            alias: '',
            icon: 'icon-guanggaotupian',
            remark: '',
            module: 'admin',
            type: 0,
            url: '',
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'banner',
            component_url: 'views/banner/Index',
            children_total: 0,
            level: 0,
          },
          {
            menu_id: 606,
            parent_id: 0,
            name: '问答交流',
            alias: '',
            icon: 'icon-guanggaotupian',
            remark: '',
            module: 'admin',
            type: 0,
            url: '',
            params: '',
            target: '_self',
            is_navi: 1,
            sort: 50,
            status: 1,
            linkto: '',
            marking: 'ask',
            component_url: 'views/ask/Index',
            children_total: 0,
            level: 0,
          },
        ],
      }

      if (r[1].data && Array.isArray(r[1].data)) {
        r[1].data.some((r) => {
          if (r.menu_auth && Array.isArray(r.menu_auth)) {
            r.menu_auth.some((r) => {
              authRule.push(r)
            })
          }
        })
      }

      r[0].data.some((r) => {
        if (
          store.state.user.userInfo.admin.username == 'admin' ||
          authRule.some((n) => {
            return n == r.menu_id
          })
        ) {
          if (r.is_navi == 1) {
            //菜单

            menuData.push(r)
          } else {
            //按钮
            authorization.push(r.marking)
          }
        }
      })

      store.commit({
        type: 'user/SET_AUTHORIZATION',
        authorization: authorization,
      })

      menuData.some((r) => {
        if (!r.marking) {
          console.log(r)
        }
      })
      if (menuData.length > 0) {
        var navMenu = initTreeData(menuData, 'parent_id', 'menu_id')

        store.commit({
          type: 'user/SET_MENUS',
          menus: navMenu,
        })

        var dataArr = []

        function getChildren(id) {
          var arr = []
          for (let i = 0; i < menuData.length; i++) {
            if (menuData[i].parent_id == id) {
              let obj = {
                name: `${menuData[i].marking}`,
                path: `/${menuData[i].marking}`,
                meta: {
                  title: menuData[i].name,
                },
                component: loadView(menuData[i].component_url),
              }

              if (getChildren(menuData[i].menu_id)) {
                obj.children = getChildren(menuData[i].menu_id)
              }
              arr.push(obj)
            }
          }
          if (arr.length > 0) {
            return arr
          } else {
            return null
          }
        }

        for (let i = 0; i < menuData.length; i++) {
          if (menuData[i].parent_id == 0) {
            let obj = {
              name: `${menuData[i].marking}`,
              path: `/${menuData[i].marking}`,
              meta: {
                title: menuData[i].name,
              },
              component: loadView(menuData[i].component_url),
            }
            if (getChildren(menuData[i].menu_id)) {
              obj.children = getChildren(menuData[i].menu_id)
            }
            dataArr.push(obj)
          }
        }

        var href = dataArr[0].linkTo ? dataArr[0].linkTo : dataArr[0].path

        function recursion(data) {
          if (data.children && data.children.length > 0) {
            recursion(data.children[0])
          } else {
            href = data.linkTo ? data.linkTo : data.path

            dataArr.push({
              path: '*',
              redirect: href,
              hidden: true,
            })

            store
              .dispatch('user/setLoadMenus', true)
              .then(() => {
                router.addRoutes(dataArr)
                next({
                  ...to,
                  replace: true,
                }) // hack方法确保addRoutes已完成
              })
              .catch(() => {})
          }
        }
        recursion(dataArr[0])
      } else {
        Message.error('该用户没有分配权限！')
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const loadView = (view) => {
  if (view) {
    return (resolve) => require([`@/${view}.vue`], resolve)
    //return () => import(`@/${view}.vue`);
  } else {
    return {
      render: (c) => c('router-view'),
    }
  }
}

export default router
