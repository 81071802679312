import Axios from '@/axios'

/**

 * 资讯管理内容
 * -  资讯管理内容列表 get.article.list
 * -  资讯管理内容删除 del.article.list
 * -  资讯管理内容修改 set.article.item
 * -  资讯管理内容新增 add.article.item
 */
export const article = function (data={}) {
  return Axios({
    url: '/adminapi/v1/article',
    method: 'post',
    data,
  })
}
