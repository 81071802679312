// 树结构数据处理
export const initTreeData = function (s,pid='parentId',id='id') {
  let dataArr = [];

  function getChildren(activeId) {
    let arr = [];
    for (let i = 0; i < s.length; i++) {
      if (activeId!=0&&s[i][pid] == activeId) {
        let obj = s[i];
        if (getChildren(obj[id])) {
          obj.children = getChildren(obj[id])
        }
        arr.push(obj);
      }
    }
    if (arr.length > 0) {
      return arr;
    } else {
      return null;
    }
  }
  for (let i = 0; i < s.length; i++) {
    if (s[i][pid] == 0) {

      let obj = s[i];
      if (getChildren(obj[id])) {
        obj.children = getChildren(obj[id])
      }

      dataArr.push(obj);
    }
  }
  return dataArr;
};

//函数节流 防抖
export const throttle = (function(){
  var timer;
  var timeHistory = new Date().getTime();
  return function(callback,time=1000){
    if(timer) clearTimeout(timer);

    var t = new Date().getTime() - timeHistory;

    if(t >time){
      timeHistory = new Date().getTime();
      callback();
    }else {
      timer = setTimeout(function(){
        timeHistory = new Date().getTime();
        callback();
      },time);
    };
  };
})();


//生成uuid
export const getUUID = function () {
  var d = new Date().getTime();
  if (window.performance && typeof window.performance.now === "function") {
    d += performance.now(); //use high-precision timer if available
  }
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
};

/**
 * dateFormater('YYYY-MM-DD HH:mm', t) ==> 2019-06-26 18:30
 * dateFormater('YYYYMMDDHHmm', t) ==> 201906261830
 */
export const dateFormater =function(formater, t){
  let date = t ? new Date(t) : new Date(),
      Y = date.getFullYear() + '',
      M = date.getMonth() + 1,
      D = date.getDate(),
      H = date.getHours(),
      m = date.getMinutes(),
      s = date.getSeconds();
  return formater.replace(/YYYY|yyyy/g,Y)
      .replace(/YY|yy/g,Y.substr(2,2))
      .replace(/MM/g,(M<10?'0':'') + M)
      .replace(/DD/g,(D<10?'0':'') + D)
      .replace(/HH|hh/g,(H<10?'0':'') + H)
      .replace(/mm/g,(m<10?'0':'') + m)
      .replace(/ss/g,(s<10?'0':'') + s)
};
