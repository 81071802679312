import Axios from '@/axios'

/**

 * 会员中心管理
 * -  会员中心管理列表 get.user.level.list
 * -  会员中心管理删除 del.user.level.list
 * -  会员中心管理修改 set.user.level.item
 * -  会员中心管理新增 add.user.level.item

 */
export const userLevel = function (data={}) {
  return Axios({
    url: '/adminapi/v1/user_level',
    method: 'post',
    data,
  })
}
