import Axios from '@/axios'

/**

 * 资讯管理管理分类
 * -  资讯管理管理分类列表 get.article.cat.list
 * -  资讯管理管理分类删除 del.article.cat.list
 * -  资讯管理管理分类修改 set.article.cat.item
 * -  资讯管理管理分类新增 add.article.cat.item
 */
export const articleCategory = function (data={}) {
  return Axios({
    url: '/adminapi/v1/article_cat',
    method: 'post',
    data,
  })
}
