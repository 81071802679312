import Axios from '@/axios'

/**

 * 商品管理
 * -  商品列表 get.goods.list
 * -  商品删除 del.goods.list
 * -  商品修改 set.goods.item
 * -  商品新增 add.goods.item
 */
export const goods = function (data={}) {
  return Axios({
    url: '/adminapi/v1/goods',
    method: 'post',
    data,
  })
}
