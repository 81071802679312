import Axios from '@/axios'


export const adsPosition = function (data={}) {
  return Axios({
    url: '/adminapi/v1/ads_position',
    method: 'post',
    data,
  })
}

export const ads = function (data={}) {
  return Axios({
    url: '/adminapi/v1/ads',
    method: 'post',
    data,
  })
}
