import axios from "axios";
import store from '@/store/index.js'
import Router from "@/router/index.js";
import qs from 'qs'

import {Message} from "element-ui";

axios.defaults.timeout = 0;

axios.defaults.transformRequest = [function (data) {
    return qs.stringify(data)
}];


//由于修改了env文件需要重启项目 本人比较懒所以在这里判断环境
if(process.env.NODE_ENV=='development'){//如果是dev环境

    axios.defaults.baseURL = '/'

}else{
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
};
//axios.defaults.withCredentials = true;

// Http's request 拦截器
axios.interceptors.request.use(
    config => {
        if(config.url!='/adminapi/v1/admin'&&config.data.method!='login.admin.user'){//不是登录接口
            config.data = config.data.combination({
                // appid:'86757521',
                // sign:'7e05d41a2abbd1e3050c76bb25e5a46e',
                // timestamp:'1659360876',
                token:store.state.user.userInfo.token.token,
            })
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);
axios.interceptors.response.use(
    data => {
        if (data.status != 200) {
            Message.error(data.status);
            return Promise.reject(data);

        } else {
            if (data.data.status == 200) {
                return Promise.resolve(data.data);
            } else if (data.data.status == 401) {
                Message.error('登录超时，请重新登录');
                Router.push('/login');
                return Promise.reject(data);
            } else {
                Message.error(data.data.message?data.data.message:'请求错误');
                return Promise.reject(data);
            }
        }
        return Promise.resolve(data);
    },
    err => {

        var msg = '请求错误';
        try {
            msg = err.response.data.message;

            if(err.response.data.status == 401){

                Message.error('登录超时，请重新登录');
                Router.push('/login');
                return Promise.reject(err);
            }

        }catch (e) {

        };
        Message.error(msg);
        return Promise.reject(err);
    }
);
export default axios;
