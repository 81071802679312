import Axios from '@/axios'

/**

 * 简历管理
 * -  简历管理列表 get.resume.list
 * -  简历管理删除 del.resume.list
 * -  简历管理修改 set.resume.item
 * -  简历管理新增 add.resume.item

 */
export const resume = function (data={}) {
  return Axios({
    url: '/adminapi/v1/resume',
    method: 'post',
    data,
  })
}
