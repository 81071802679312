import Axios from '@/axios'

/**

 * 订单管理
 * -  订单列表 get.order.list
 */
export const orderList = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/order',
    method: 'post',
    data,
  })
}

// 发货
export const seedOrder = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/order',
    method: 'post',
    data,
  })
}

// 取消
export const cancelOrder = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/order',
    method: 'post',
    data,
  })
}
