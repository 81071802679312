import Axios from '@/axios'
import qs from "qs";


export const login = function (data={}) {
  return Axios({
    url: '/adminapi/v1/admin',
    method: 'post',
    data,
  })
}
