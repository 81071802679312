import * as login from './login/index'
import * as menu from './menu/index'
import * as user from './user/index'
import * as role from './role/index'
import * as dict from './dict/index'
import * as dictClassify from './dictClassify/index'
import * as log from './log/index'
import * as course from './course/index'
import * as organ from './organ/index'
import * as goodsCategory from './goodsCategory/index'
import * as goods from './goods/index'
import * as coupon from './coupon/index'
import * as report from './report/index'

import * as articleCat from './articleCat/index'
import * as article from './article/index'
import * as task from './task/index'
import * as taskAttr from './taskAttr/index'
import * as jobs from './jobs/index'
import * as jobsTag from './jobsTag/index'
import * as position from './position/index'
import * as resume from './resume/index'
import * as userLevel from './userLevel/index'
import * as delivery from './delivery/index'
import * as adsPosition from './adsPosition/index'
import * as addressTag from './addressTag/index'
import * as banner from './banner/index'
import * as ask from './ask/index'
import * as orderManage from './orderManage/index'
import * as industryManage from './industryManage/index'
import * as setting from './setting/index'
import * as orderInvoice from './orderInvoice/index'

const api = {
  login, //登录
  menu, //菜单
  user, //用户
  role, //角色
  dict, //字典
  dictClassify, //字典类型
  log, //操作日志
  course, //课程
  organ, //机构/讲师
  goodsCategory, //商品类型
  goods, //商品
  coupon, //优惠券
  articleCat, //资讯管理分类
  article, //资讯管理
  task, //积分任务管理
  taskAttr, //成就任务详情
  jobs, //招聘管理
  jobsTag, //招聘管理
  position, //岗位管理
  resume, //简历管理
  userLevel, //会员中心管理
  report,
  delivery,
  adsPosition,
  addressTag,
  banner,
  ask, //问答交流
  orderManage, //订单管理
  industryManage, //行业
  setting,
  orderInvoice, //发票管理
}

export default api
