import Axios from '@/axios'

/**
 * 结构/讲师列表管理
 * - 新增结构/讲师 add.organ.item
 * - 结构/讲师列表 get.organ.list
 * - 结构/讲师详情 get.organ.item
 * - 编辑结构/讲师信息 set.organ.item
 */
export const organ = function (data={}) {
  return Axios({
    url: '/adminapi/v1/Organ',
    method: 'post',
    data,
  })
}
