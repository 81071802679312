import Axios from '@/axios'

/**

 * 招聘管理
 * -  招聘管理列表 get.jobs.list
 * -  招聘管理删除 del.jobs.list
 * -  招聘管理修改 set.jobs.item
 * -  招聘管理新增 add.jobs.item
 * -  批量修改状态    set.jobs.status
 */
export const jobs = function (data={}) {
  return Axios({
    url: '/adminapi/v1/jobs',
    method: 'post',
    data,
  })
}
