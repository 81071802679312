import Axios from '@/axios'
import { Message } from 'element-ui'
import store from '@/store'

export const admin = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/admin',
    method: 'post',
    data,
  })
}

/**
 * 用户管理
 * - 新增用户 add.user.item
 * - 用户列表 get.user.list
 * - 用户详情 get.user.item
 * - 编辑用户信息 set.user.item
 * - 批量设置用户 set.user.status
 * - 批量删除用户 del.user.list
 * - 重置密码 set.user.password
 */
export const user = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/user',
    method: 'post',
    data,
  })
}


export const userimport = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/user',
    method: 'post',
    data,
  })
}
// 用户管理 - 获取账户资金 get.user.money.info
export const user_money = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/user_money',
    method: 'post',
    data,
  })
}

/**
 * 会员等级管理
 * - 添加一个账号等级 add.user.level.item
 * - 编辑一个账号等级 set.user.level.item
 * - 批量删除账号等级 del.user.level.list
 * - 获取一个账号等级 get.user.level.item
 * - 获取账号等级列表 get.user.level.list
 */
export const user_level = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/user_level',
    method: 'post',
    data,
  })
}

/**
 * 用户收藏管理
 * - 收藏列表 get.user_collection.list
 */
export const user_collection = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/user_collection',
    method: 'post',
    data,
  })
}

/**
 * 用户反馈管理
 * - 反馈列表 get.user_idea.list
 */
export const user_idea = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/user_idea',
    method: 'post',
    data,
  })
}

/**
 * 用户评论管理
 * - 获取用户评论列表 get.user_moment.list
 * - 添加一个用户评论 add.user_moment.item
 * - 编辑一个用户评论 set.user_moment.item
 * - 获取一个用户评论 get.user_moment.item
 * - 删除用户评论 del.user_moment.list
 */
export const user_moment = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/user_moment',
    method: 'post',
    data,
  })
}

// 修改回复状态，审核回复用
export const set_user_moment_status = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/user_moment_log',
    method: 'post',
    data,
  })
}

// 删除评论回复
export const del_user_moment_log = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/user_moment_log',
    method: 'post',
    data,
  })
}

export const user_address = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/user_address',
    method: 'post',
    data,
  })
}

// 获取待审核列表
export const user_audit_list = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/user',
    method: 'post',
    data,
  })
}
// 认证审核
export const user_audit_status = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/user',
    method: 'post',
    data,
  })
}
// 获取待审核列表
export const user_real_list = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/user_real',
    method: 'post',
    data,
  })
}
// 认证审核
export const user_real_status = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/user_real',
    method: 'post',
    data,
  })
}
