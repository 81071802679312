<template>
  <div id="app" :class="{ isCollapse: adaptation.isCollapse }">
    <template v-if="verificationLogin">
      <router-view></router-view>
    </template>
    <template v-else>
      <div class="menu transition">
        <div class="logo">
          <img v-if="!adaptation.isCollapse" :src="logo" class="transition" />
          <template v-else>
            <img :src="logomin" class="transition" />
            <div class="n">incoPat</div>
          </template>
        </div>
        <el-scrollbar class="menu_content">
          <VerMenuEle :isCollapse="adaptation.isCollapse"></VerMenuEle>
        </el-scrollbar>
      </div>
      <Header
        class="header transition"
        :isCollapse.sync="adaptation.isCollapse"
      />
      <router-view class="main transition"></router-view>
    </template>
  </div>
</template>

<script>
import { throttle } from '@/util/common'
export default {
  name: 'App',
  components: {
    Header: () => import('@/views/common/Header.vue'),
    VerMenuEle: () => import('@/views/common/VerMenuEle.vue'),
  },
  data() {
    return {
      logo: require('@/assets/images/logomax.png'),
      logomin: require('@/assets/images/logo.png'),
      verificationLogin: true,
      adaptation: {
        isCollapse: false,
        width: window.innerWidth,
        height: window.innerHeight,
      },
    }
  },
  provide() {
    return {
      adaptation: this.adaptation,
    }
  },
  watch: {
    $route: {
      //immediate:true,//初始化立即执行
      deep: true, //对象深度监测
      handler: function (to) {
        this.verificationLogin = to.meta.verificationLogin
      },
    },
  },
  methods: {},
  mounted() {
    window.addEventListener('resize', (e) => {
      this.adaptation.width = e.target.innerWidth
      this.adaptation.height = e.target.innerHeight
    })
  },
}
</script>

<style lang="scss">
@import '@/styles/config.scss';
@import url('https://at.alicdn.com/t/c/font_3647994_pm6n5498qfb.css');
#app {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  background: #f6f9fd;
  //@media only screen and (min-aspect-ratio: 900/460) {
  //    @media screen and (max-height: 460px) {
  //        height: 460px;
  //    }
  //}
  //@media only screen and (max-aspect-ratio: 900/460) {
  //    @media screen and (max-width: 900px) {
  //        width: 900px;
  //    }
  //}
  .transition {
    transition: 0.1s cubic-bezier(0.92, 1.1, 0.71, 0.94);
  }
  &.isCollapse {
    > .header {
      left: 64px;
    }
    > .menu {
      width: 64px;
      .logo {
        padding-top: 10px;
        padding-bottom: 10px;
        img {
          width: 44px;
          height: 44px;
          padding: 5px;
        }
        .n {
          font-size: 12px;
          line-height: 16px;
        }
      }
      .menu_content {
        height: calc(100% - 180px);
        .el-submenu {
          &.is-active {
            background: #444444 !important;
          }
        }
      }
    }
    > .main {
      left: 64px;
    }
  }
  > .header {
    position: absolute;
    top: 0;
    left: 280px;
    right: 0;
    height: 70px;
    border-bottom: 1px solid #ddd;
    background: #fff;
    z-index: 999;
  }
  > .menu {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 280px;
    background: #333;
    .logo {
      text-align: center;
      padding: 30px 0;
      img {
        width: 230px;
        // height: 80px;
        padding: 10px;
        margin-bottom: 10px;
        display: block;
        margin: 0 auto;
      }
      .n {
        color: #fff;
        line-height: 30px;
        font-size: 24px;
      }
      + .menu_content {
        height: calc(100% - 110px);
        padding-top: 0;
      }
    }
    .menu_content {
      //height:calc(100% - 174px);
      height: 100%;
      padding-top: 20px;
      .el-scrollbar__view {
        padding-bottom: 35px;
      }
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
  }
  > .main {
    position: absolute;
    top: 71px;
    left: 280px;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
}
.el-menu--vertical {
  .el-menu--popup {
    background: #333 !important;
    .iconfont {
      font-size: 16px;
      margin-right: 5px;
      color: #fff;
    }
    i {
      color: #f2f3f5;
    }
    .el-menu-item {
      &.is-active {
        color: #fff;
        background: #444444 !important;
      }
    }
  }
}
</style>
