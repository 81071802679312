import Axios from '@/axios'

/**
 * 课程列表管理
 * - 新增课程 add.course.item
 * - 课程列表 get.course.list
 * - 课程详情 get.course.item
 * - 编辑课程信息 set.course.item
 * - 批量设置课程状态 set.course.status
 * - 批量设置课程置顶 set.course.top
 * - 删除课程 del.course.item
 */
export const course = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/course',
    method: 'post',
    data,
  })
}

/**
 * 课程章节管理
 * - 添加视频详情 add.course_video.item
 * - 获取视频详情列表 get.course_video.list
 * - 获取一条视频详情 get.course_video.item
 * - 编辑视频详情 set.course_video.item
 * - 批量删除视频详情 del.course_video.list
 */
export const course_video = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/course_video',
    method: 'post',
    data,
  })
}

/**
 * 课程分类管理
 * - 添加课程分类 add.course_cat.item
 * - 获取课程分类列表 get.course_cat.list
 * - 获取一条课程分类 get.course_cat.item
 * - 编辑课程分类 set.course_cat.item
 * - 批量编辑课程分类状态 set.course_cat.status
 * - 批量删除课程分类 del.course_cat.list
 */
export const course_cat = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/course_cat',
    method: 'post',
    data,
  })
}

/**
 * 课程标签管理
 * - 添加课程标签 add.course_tag.item
 * - 获取课程标签列表 get.course_tag.list
 * - 获取一条课程标签 get.course_tag.item
 * - 编辑课程标签 set.course_tag.item
 * - 批量编辑课程标签状态 set.course_tag.status
 * - 批量删除课程标签 del.course_tag.list
 */
export const course_tag = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/course_tag',
    method: 'post',
    data,
  })
}

/**
 * 笔记管理
 * - 获取笔记列表 get.course_note.list
 * - 审核笔记 set.course_note.status
 * - 批量删除课程笔记 del.course_note.list
 */
export const course_note = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/course_note',
    method: 'post',
    data,
  })
}

/**
 * 笔记评论管理
 * - 获取笔记评论列表 get.course_note_reply.list
 * - 审核笔记评论 set.course_note_reply.status
 */
export const course_note_reply = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/course_note_reply',
    method: 'post',
    data,
  })
}

