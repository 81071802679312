import Vue from 'vue'

Vue.filter('dateFormat', function (value, fmt) {
  if (value) {
    if (fmt) {
      if (typeof value == 'string') {
        value = value.replace(/-/g, '/')
      }

      return new Date(value).format(fmt)
    } else {
      return new Date(value).format('yyyy-MM-dd')
    }
  } else {
    return ''
  }
})

Vue.filter('portrait', function (value) {
  return value ? value : require('@/assets/images/portrait.png')
})

Vue.filter('status', function (value) {
  return value == 1 ? '启用' : '禁用'
})

Vue.filter('dict', function (value, data, key = 'val') {
  if (value) {
    for (let i = 0; i < data.length; i++) {
      if (data[i][key] == value) {
        value = data[i].name
      }
    }
    return value
  } else {
    return ''
  }
})
Vue.filter('showCouponModule', function (value) {
  return value == 1 ? '会员卡' : value == 2 ? '课程' : '通用'
})
Vue.filter('showCouponType', function (value) {
  //1:新用户红包 2:专属红包 3:邀新红包
  if (value == 1) {
    return '新用户优惠券'
  } else if (value == 2) {
    return '专属优惠券'
  } else if (value == 3) {
    return '邀新奖励优惠券'
  }
  return ''
})
Vue.filter('showIsRecommend', function (value) {
  return value == 1 ? '是' : '否'
})
Vue.filter('showTaskType', function (value) {
  return value == 1 ? '每日任务' : '成就'
})

Vue.filter('showJobsStatus', function (value) {
  if (value == '0') {
    return '待审核'
  } else if (value == '1') {
    return '启用'
  } else {
    return '禁用'
  }
})
Vue.filter('showStatus', function (value) {
  return value == 1 ? '开启' : '禁用'
})
Vue.filter('sex', function (value) {
  return value == 1 ? '男' : '女'
})
Vue.filter('yesOrNo', function (value) {
  return value == 1 ? '是' : '否'
})

Vue.filter(
  'cascader',
  function (
    value,
    data,
    key = 'id',
    label = 'name',
    pid = 'parent_id',
    separator = '/'
  ) {
    if (value) {
      var arr = []

      function getParent(item) {
        for (let i = 0; i < data.length; i++) {
          let n = data[i]
          if (n.id == item[pid]) {
            arr.unshift(n[label])
            if (n[pid] != 0) {
              getParent(n)
            }
          }
        }
      }

      for (let i = 0; i < data.length; i++) {
        let item = data[i]

        if (item[key] == value) {
          arr.push(item[label])
          if (item[pid] != 0) {
            getParent(item)
          }
        }
      }
      return arr.join(separator)
    } else {
      return ''
    }
  }
)

Vue.filter('target', function (value) {
  if (value == '_self') {
    return '本页面'
  } else if (value == '_blank') {
    return '新页面'
  }
  return ''
})
