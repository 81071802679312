import Axios from '@/axios'

/**

 * 行业数据管理
 * -  行业列表 get.patent.list
 */
export const patentList = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/patent',
    method: 'post',
    data,
  })
}

// 导入
export const patentToLead = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/patent',
    method: 'post',
    data,
  })
}
