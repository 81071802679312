import Axios from '@/axios'
import { Message } from "element-ui";
import store from '@/store'
import { initTreeData } from '@/util/common'



export const area = function (cache=true) {

    return new Promise((resolve,reject) => {

      if(cache&&store.state.dict.data.area){
        resolve(store.state.dict.data.area)
      }else {
        Axios({
          url: '/adminapi/v1/region',
          method: 'post',
          data:{
            method:'get.region.son.list'
          },
        }).then(res=>{
          res.treeData = initTreeData(res.data, 'parent_id', 'region_id')
          store.commit({
            type: 'dict/SET_DATA',
            data:res,
            code:'area',
          });
          resolve(res);
        }).catch(err => {
          reject(err);
        });
      }
    });


};
