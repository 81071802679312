import Axios from '@/axios'

/**

 * 成就任务详情管理
 * -  成就任务详情列表 get.task_attr.list
 * -  成就任务详情删除 del.task_attr.list
 * -  成就任务详情修改 set.task_attr.item
 * -  成就任务详情新增 add.task_attr.item
 */
export const taskAttr = function (data={}) {
  return Axios({
    url: '/adminapi/v1/task_attr',
    method: 'post',
    data,
  })
}
