import Axios from '@/axios'

/**

 * 发票管理
 * -  发票列表 get.order_invoice.list
 * -  设置发票状态 set.order_invoice.item
 */
export const invoiceList = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/order_invoice',
    method: 'post',
    data,
  })
}

export const setInvoice = function (data = {}) {
  return Axios({
    url: '/adminapi/v1/order_invoice',
    method: 'post',
    data,
  })
}
