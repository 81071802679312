import Axios from '@/axios'

/**

 * 积分任务管理
 * -  积分任务管理列表 get.task.list
 * -  积分任务管理删除 del.task.list
 * -  积分任务管理修改 set.task.item
 * -  积分任务管理新增 add.task.item
 * -  批量修改状态    set.task.status
 */
export const task = function (data={}) {
  return Axios({
    url: '/adminapi/v1/task',
    method: 'post',
    data,
  })
}
